<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="搜索关键字" label-width="100px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称/编码" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="编码" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.t_code }}
				  </template>
				</el-table-column>
				<el-table-column label="年级号" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nj_no }}
				  </template>
				</el-table-column>
				<el-table-column label="奥赛指导" min-width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.aszd }}
				  </template>
				</el-table-column>
				<el-table-column label="非奥赛指导" min-width="120" align="center">
				  <template slot-scope="scope">
					{{ scope.row.un_aszd }}
				  </template>
				</el-table-column>
				<el-table-column label="社会实践" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.shsj }}
				  </template>
				</el-table-column>
				<el-table-column label="研究学习" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.yjxx }}
				  </template>
				</el-table-column>
				<el-table-column label="社团指导" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.stzd }}
				  </template>
				</el-table-column>
				<el-table-column label="总分" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.total }}
				  </template>
				</el-table-column>
				<el-table-column label="填写部门" min-width="140" align="center">
				  <template slot-scope="scope">
					{{ scope.row.department }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" v-if="isFinish">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.size"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="650px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small">
		    <el-form-item label="年级号" prop="nj_no">
		      <el-input v-model="form.nj_no" placeholder="请输入年级号如:2023" type="text" clearable />
		    </el-form-item>
			<el-form-item label="学科" prop="xueke">
			  <el-input v-model="form.xueke" placeholder="请输入学科" type="text" clearable />
			</el-form-item>
		    <el-form-item label="老师编码" prop="t_code">
		      <el-input v-model="form.t_code" placeholder="请输入老师编码" type="text" clearable />
		    </el-form-item>
			<el-form-item label="老师姓名" prop="t_name">
			  <el-input v-model="form.t_name" placeholder="请输入老师姓名" type="text" clearable />
			</el-form-item>
			<el-form-item label="奥赛指导" prop="aszd">
			  <el-input v-model="form.aszd" placeholder="请输入奥赛指导分数(最大1分)" type="number" clearable />
			</el-form-item>
			<el-form-item label="非奥赛指导" prop="un_aszd">
			  <el-input v-model="form.un_aszd" placeholder="请输入非奥赛指导得分(最大1分)" type="number" clearable />
			</el-form-item>
			<el-form-item label="社会实践" prop="shsj">
			  <el-input v-model="form.shsj" placeholder="请输入社会实践得分(最大1分)" type="number" clearable />
			</el-form-item>
			<el-form-item label="研究学习" prop="yjxx">
			  <el-input v-model="form.yjxx" placeholder="请输入研究学习得分(最大1分)" type="number" clearable />
			</el-form-item>
			<el-form-item label="社团指导" prop="stzd">
			  <el-input v-model="form.stzd" placeholder="请输入社团指导得分(最大1分)" type="number" clearable />
			</el-form-item>
			<el-form-item label="总分" prop="total">
			  <el-input v-model="form.total" placeholder="请输入总分" type="number" clearable />
			</el-form-item>
			<el-form-item label="填写部门" prop="department">
			  <el-input v-model="form.department" placeholder="请输入填写部门" type="text" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Breadcrumb,
		},
		data() {
			const validatorRange= (rule, value, callback) =>{
			    if(value >= 0 && value <= 1) return callback();
			    else return callback(new Error(`请输入0到1的数字`))
			}
			return {
				preUrl: 'hdzd_list',
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					p_id:'',
					s_id:'',
					nj_no:'',
					xueke:'',
					t_code:'',
					t_name:'',
					aszd:0,
					un_aszd:0,
					shsj:0,
					yjxx:0,
					stzd:0,
					total:0,
					department:'',
				},
				rules:{
					nj_no: [
					  { required: true, message: '请输入年级号', trigger: 'blur' }
					],
					xueke: [
					  { required: true, message: '请输入学科', trigger: 'blur' }
					],
					t_code: [
					  { required: true, message: '请输入老师编码', trigger: 'blur' }
					],
					t_name: [
					  { required: true, message: '请输入老师名称', trigger: 'blur' }
					],
					aszd: [
					  { required: true, message: '请输入奥赛指导得分', trigger: 'blur' },
					  { validator: validatorRange, trigger: 'blur' }
					],
					un_aszd: [
					  { required: true, message: '请输入非奥赛指导得分', trigger: 'blur' },
					  { validator: validatorRange, trigger: 'blur' }
					],
					shsj: [
					  { required: true, message: '请输入社会实践得分', trigger: 'blur' },
					  { validator: validatorRange, trigger: 'blur' }
					],
					yjxx: [
					  { required: true, message: '请输入研究学习得分', trigger: 'blur' },
					  { validator: validatorRange, trigger: 'blur' }
					],
					stzd: [
					  { required: true, message: '请输入社团指导得分', trigger: 'blur' },
					  { validator: validatorRange, trigger: 'blur' }
					],
					total: [
					  { required: true, message: '请输入总分', trigger: 'blur' },
					],
					department: [
					  { required: true, message: '请输入填写部门', trigger: 'blur' },
					],
				},
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
		},
		computed:{
			isFinish(){
				if(this.list.length > 0 && this.list[0].is_finish == 2){
					return true
				}else{
					return false
				}
			}
		},
		methods:{
			reset() {
			  this.form = {
			   p_id:this.id,
			   s_id:this.s_id,
			   nj_no:'',
			   xueke:'',
			   t_code:'',
			   t_name:'',
			   aszd:0,
			   un_aszd:0,
			   shsj:0,
			   yjxx:0,
			   stzd:0,
			   total:0,
			   department:'',
			  }
			  this.resetForm('form')
			},
		}
	}
</script>

<style>
</style>